import { Button, Icon, TextField } from '@nike/eds'
import { UnitDetailsPanel } from 'components/left-detail-panel'
import { MainPanel } from 'components/main-panel'
import { NumPad } from 'components/num-pad'
import { useEffect, useState } from 'react'
import { type Unit } from 'types'
import { type ApiHandlerContext } from 'utils/useApiHandler'

interface ScanToteProps {
  unitData: Unit
  handleReturn: () => void
  updateUnitToteScanContext: ApiHandlerContext<Unit>
}

const TOTE_BARCODE_LENGTH = 20

export const ScanTote = ({ unitData, handleReturn, updateUnitToteScanContext }: ScanToteProps) => {
  const [error, setError] = useState('')
  const [toteBarcode, setToteBarcode] = useState('')
  const [showNumPad, setShowNumPad] = useState(false)

  useEffect(() => {
    if (toteBarcode.length > 0 && toteBarcode.length > TOTE_BARCODE_LENGTH) {
      setError('Invalid putaway tote barcode')
    } else {
      setError('')
    }
  }, [toteBarcode])

  const keyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleConfirm()
    }
  }

  const handleConfirm = () => {
    if (error === '' && toteBarcode.length > 0 && !updateUnitToteScanContext.isLoading) {
      updateUnitToteScanContext.handleApiCall({
        ...unitData,
        toteBarcode,
        status: 'HANDLED'
      }).then(() => { setToteBarcode('') })
    }
  }

  const leftPanelContent = <UnitDetailsPanel unit={unitData} showImage={false} />

  const mainPanelContent = <div style={{ display: '-webkit-box' }} className="flex flex-col w-full">
    <div style={{ width: '900px' }} className={`flex-grow ${showNumPad ? 'mb-16' : ''} m-auto scanTitle`}>
      <TextField
        id="toteBarcode"
        label="Please scan the putaway tote:"
        placeholder="Tote barcode"
        value={toteBarcode}
        onChange={(e) => {
          setToteBarcode(e.currentTarget.value)
        }}
        autoFocus
        maxChars={TOTE_BARCODE_LENGTH}
        hasErrors={error !== ''}
        errorMessage={error}
        onKeyUp={keyUpHandler}
        onBlur={(e) => { e.target.focus() }}
        beforeSlot={<Icon name="Scan" />}
      />
      <NumPad text={toteBarcode} setText={setToteBarcode} showNumPad={showNumPad} setShowNumPad={setShowNumPad} confirm={handleConfirm} />
    </div>
    <Button onClick={handleReturn} className='absolute text-2xl bottom-0' variant='secondary'>
      Return
    </Button>
  </div>

  return <MainPanel leftPanelContent={leftPanelContent} mainPanelContent={mainPanelContent} />
}
