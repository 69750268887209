import { BGradeReason, BGradeReasonSEC, CGradeReason, CGradeReasonSEC } from 'types'

import { isSec } from './dcContext'

export const getGradeReasons = (grade: string) => {
  return isSec
    ? grade === 'B-Grade' ? BGradeReasonSEC : CGradeReasonSEC
    : grade === 'B-Grade' ? BGradeReason : CGradeReason
}
export const getCGradeQualityCode = () => {
  return isSec ? '03' : '02'
}
